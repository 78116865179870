import {useEffect} from "react";
import type {FC} from "react";
import {useRouter} from "next-translate-routes/router";
import PropTypes from "prop-types";
import {Box, Button, Drawer, useMediaQuery, MenuItem, Typography, Grid} from "@mui/material";
import type {Theme} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTranslation} from "next-i18next";
import {Link} from "./link"

interface MainSidebarProps {
    onClose?: () => void;
    open?: boolean;
}

const MainSidebarLink = styled(Link)(
    ({theme}) => ({
        borderRadius: theme.shape.borderRadius,
        display: "block",
        padding: theme.spacing(1.5),
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    })
);

export const MainSidebar: FC<MainSidebarProps> = (props) => {
        const {onClose, open} = props;
        const {t} = useTranslation()
        const router = useRouter();
        const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));


        const {locale, locales, pathname, query, route, push} = router

        const handlePathChange = () => {
            if (open) {
                onClose?.();
            }
        };

        useEffect(
            handlePathChange,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [router.asPath]
        );

        return (
            <Drawer
                anchor='right'
                onClose={onClose}
                open={!lgUp && open}
                PaperProps={{sx: {width: 156}}}
                sx={{
                    zIndex: (theme) => theme.zIndex.appBar + 100
                }}
                variant='temporary'
            >
                <Box sx={{p: 2}}>
                    <MainSidebarLink
                        href='/diving'
                            color='textSecondary'
                            underline='none'
                            variant='subtitle2'
                        >
                            {t("explore-trips")}
                        </MainSidebarLink>


                        <MainSidebarLink
                            href='/concept'
                            color='textSecondary'
                            underline='none'
                            variant='subtitle2'
                        >
                            {t("concept")}
                        </MainSidebarLink>

                        <MainSidebarLink
                            href='/contact'
                            color='textSecondary'
                            underline='none'
                            variant='subtitle2'
                        >
                            Contact
                        </MainSidebarLink>

                        <MainSidebarLink
                            href='/articles'
                            color='textSecondary'
                            underline='none'
                            variant='subtitle2'
                        >
                            Articles
                        </MainSidebarLink>

                </Box>

                <Grid container justifyContent={"space-around"} sx={{position: "absolute", bottom: 0}}>

                    {locales?.filter((val) => val !== "default").map((val, idx) => {

                            return <Grid item key={idx} sx={{p: 1}}>
                                <Link href={"/"} locale={val}>
                                    <img src={`/images/flags/lang/${val}.png`}
                                         srcSet={`/images/flags/lang/${val}.png, /images/flags/lang/${val}@2x.png 2x`}
                                         style={{
                                             height: 28,
                                             width: 28,
                                             marginRight: 6,
                                             verticalAlign: "middle"
                                         }}
                                         alt={
                                             val
                                         }
                                    />
                                </Link>
                            </Grid>
                        }
                    )}
                </Grid>

            </Drawer>
        );
    }
;

MainSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
