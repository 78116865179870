import type {FC} from "react";
import PropTypes from "prop-types";
import {AppBar, Box, Container, IconButton, Toolbar} from "@mui/material";
import {Menu as MenuIcon} from "../icons/menu";
import {Logo} from "./logo";
import {useTranslation} from "next-i18next";
import {AccountButton} from "./account-button";
import {SiteLanguagePicker} from "./site-language-picker";
import {Link, LinkProps} from "./link"
interface MainNavbarProps {
    onOpenSidebar?: () => void;
    hero?: boolean;
}
interface CustomNavLinkProps extends LinkProps{
    hero?: boolean
    title: string
}


const CustomNavLink: FC<CustomNavLinkProps> = ({href, title, hero}) => {
    return <Link
        href={href}
        color={hero ? "white" : "textPrimary"}
        sx={{
            ml: 2,
            fontWeight: 500
        }}
        underline='none'
        variant='subtitle1'
    >
        {title}
    </Link>
}


export const MainNavbar: FC<MainNavbarProps> = (props) => {

    const {onOpenSidebar, hero} = props;
    const {t, i18n} = useTranslation();
    return (
        <AppBar
            elevation={0}
            sx={{
                backgroundColor: hero ? "transparent" : "background.paper",
                borderBottomColor: "divider",
                borderBottomStyle: hero ? "none" : {
                    xs: "solid",
                    sm: "solid"
                },
                borderBottomWidth: 1,
                color: "text.secondary"
            }}
            position={hero ? "static" : "fixed"}
        >
            <Container maxWidth='lg' sx={{
                px: {
                    xs: 1,
                    sm: 1

                },
            }}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 48
                    }}
                >

                    {
                        hero ?

                            <SiteLanguagePicker/>
                            :
                            <Link
                                href='/'
                                aria-label={"link to home page"}
                                >
                                    <Logo
                                        variant={hero ? "light" : "primary"}
                                        sx={{
                                            display: {
                                                md: "inline",
                                                xs: "none"
                                            },
                                            height: 35,
                                            width: 196
                                        }}
                                    />
                            </Link>
                    }

                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{
                            display: {
                                md: "none"
                            }
                        }}
                        aria-label={"Open navigation menu"}
                    >
                        <MenuIcon sx={{
                            color: hero ? "white" : "black"

                        }}
                                  fontSize='small'/>
                    </IconButton>
                    <Box
                        sx={{
                            alignItems: "center",
                            display: {
                                md: "flex",
                                xs: "none"
                            }
                        }}
                    >
                        <CustomNavLink
                            hero={hero}
                            href='/diving'
                            title={t("explore-trips")}
                        />

                        <CustomNavLink
                            hero={hero}
                            href='/concept'
                            title={t("concept")}
                        />

                        <CustomNavLink
                            hero={hero}
                            href='/contact'
                            title={"Contact"}
                        />

                        <CustomNavLink
                            hero={hero}
                            href='/articles'
                            title={"Articles"}
                        />

                        <AccountButton/>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
        ;
};

MainNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};
