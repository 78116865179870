import type {FC} from "react";
import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography, Hidden
} from "@mui/material";
import {MinusOutlined as MinusOutlinedIcon} from "../icons/minus-outlined";
import {LogoOnly} from "./logo-only";
import {SiteLanguagePicker} from "./site-language-picker";
import {useTranslation} from "next-i18next";


import footerDataJson from "../generated/footer-data.json"
import {useMemo} from "react";
import {localize} from "../lib/util";
import React from "react";
import {grey} from "@mui/material/colors";
import {CanHelpYou} from "../icons/can-help-you";
import {Link} from "./link"

const socialButtons = [
    {
        file: "facebook.svg",
        alt: "facebook",
        title: "SeaCrush on Facebook",
        url: "https://www.facebook.com/SeaCrush/"
    },
    {
        file: "instagram.svg",
        alt: "instagram",
        title: "SeaCrush on Instagram",
        url: "https://www.instagram.com/seacrushexpeditions/"
    },
    {
        file: "rss.svg",
        alt: "Rss Feed",
        title: "SeaCrush Blog Articles",
        url: "/en/articles"
    },


]

interface BasicLinksInterface {
    pathname: string,
    titleKey: "title-about" | "title-privacy" | "title-terms"
}

const basicLinks: BasicLinksInterface[] = [
    {
        pathname: "/about",
        titleKey: "title-about"
    },
    {
        pathname: "/privacy",
        titleKey: "title-privacy"
    },
    {
        pathname: "/terms",
        titleKey: "title-terms"
    }
]


export const Footer: FC = (props) => {

    const {t, i18n} = useTranslation()


    const footerData = useMemo(() => {
        return localize(footerDataJson, [i18n.language, "en"])
    }, [i18n.language])


    return (
        <>
            <Box
                sx={{
                    backgroundColor: "primary.dark",
                    borderTopColor: "divider",
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    pb: {
                        md: 6,
                        xs: 3
                    },
                    pt: {
                        md: 6,
                        xs: 3
                    }
                }}
                {...props}
            >
                <Container maxWidth='lg'>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Hidden smDown>
                            <Grid item sm={3} xs={0}>
                                &nbsp;
                            </Grid>
                        </Hidden>
                        <Grid item sm={9} sx={{
                            borderBottomWidth: 1,
                            borderColor: grey[500],
                            borderBottomStyle: "solid",
                            mb: 1,
                            pb: 3,
                            color: "#fff"
                        }}>
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{
                                    textAlign: {
                                        xs: "center",
                                        sm: "right"
                                    },
                                    pb: {
                                        xs: 2,
                                        sm: 0
                                    },
                                    pr: {
                                        xs: 0,
                                        sm: 2
                                    }
                                }}>
                                    <Typography variant={"h4"}><CanHelpYou
                                        sx={{mr: 1, width: 42, height: 42, verticalAlign: "bottom"}}/>
                                        {t("can-help-you")}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                                    <Typography variant={"h4"} sx={{
                                        pb: {
                                            xs: 1,
                                            sm: 0
                                        }
                                    }}>{t("seacrush-tel")}</Typography>
                                    <Typography variant={"body1"}
                                                sx={{display: "inline-block"}}
                                    >{t("or")}&nbsp;</Typography>
                                    <Link
                                        href='/contact'
                                        color={"#fff"}
                                        sx={{display: "inline-block"}}
                                        underline='always'
                                        variant='body1'
                                    >{t("send-us-a-message")}</Link>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                    >

                        <Grid
                            item
                            md={3}
                            sm={3}
                            xs={12}
                            sx={{
                                textAlign: "center",
                                order: {
                                    md: 0,
                                    xs: 2
                                }
                            }}
                        >

                            <Box>
                                <LogoOnly variant={"light"}/>
                            </Box>
                            <SiteLanguagePicker/>


                        </Grid>
                        <Grid
                            item
                            sm={9}
                        >

                            <Grid
                                container
                                spacing={3}
                                sx={{
                                    pl: {
                                        xs: 4,
                                        sm: 1,
                                        xm: 0
                                    }
                                }}
                            >
                                {footerData.map((continent: any, index: number) => (
                                    <Grid
                                        item
                                        key={index}
                                        md={3}
                                        sm={3}
                                        sx={{
                                            order: {
                                                md: index + 2,
                                                xs: index + 1
                                            }
                                        }}
                                        xs={12}
                                    >
                                        <Typography
                                            color='primary.contrastText'
                                            variant='overline'
                                        >
                                            {continent.title}
                                        </Typography>
                                        <List disablePadding>
                                            {continent.countries.map((country: any, idx: number) => (
                                                <ListItem
                                                    disableGutters
                                                    key={idx}
                                                    sx={{
                                                        pb: 0,
                                                        pt: 0
                                                    }}
                                                >
                                                    <ListItemAvatar
                                                        sx={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            minWidth: 0,
                                                            mr: 0.5
                                                        }}
                                                    >
                                                        <MinusOutlinedIcon color='primary'/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        sx={{color: "#fff"}}
                                                        primary={(
                                                            <Link
                                                                href={{
                                                                    pathname: "/diving/[countrySlug]",
                                                                    query: {
                                                                        countrySlug: country.slug.current
                                                                    }
                                                                }}
                                                                color='primary.contrastText'
                                                                variant='subtitle2'>
                                                                {country.title}
                                                            </Link>
                                                        )}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box
                pt={3}
                pb={2}
                sx={{backgroundColor: "#383838", borderTop: "1px solid #6d6d6d"}}
            >
                <Container maxWidth={"sm"}>
                    <Grid container
                          justifyContent={"space-around"}>

                        {socialButtons.map((val, idx) => {

                            return <Grid
                                key={"social-" + idx}
                                item
                                md={3}
                                sm={4}
                                xs={3}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    order: {
                                        md: 1,
                                        xs: 4
                                    }
                                }}
                                alignItems={"center"}
                            >

                                <a style={{display: "inline-block"}}
                                   href={val.url}
                                   title={val.title}
                                   rel='noopener noreferrer'
                                   target='_blank'>
                                    <img
                                        src={`/images/${val.file}`}
                                        width={48}
                                        height={48}
                                        loading={"lazy"}
                                        style={{
                                            width: 48,
                                            height: 48,
                                            verticalAlign: "text-bottom",
                                            filter: "brightness(45%)"
                                        }} alt={val.alt}/>
                                </a>

                            </Grid>

                        })}

                    </Grid>
                </Container>

                <Typography textAlign={"center"} sx={{mt: 3}} variant={"body1"} color='#8d8d8d'>

                    {basicLinks.map<React.ReactNode>((val, idx) => {
                        return <Link
                            key={"link-" + idx}
                            href={val.pathname}
                            style={{cursor: "pointer"}}
                            color='#8d8d8d'
                            variant='body2'>
                            {
                                t(val.titleKey)
                            }
                        </Link>


                    }).reduce((prev, curr, idx) => [prev, <React.Fragment key={idx}>
                        &nbsp;-&nbsp;
                    </React.Fragment>, curr])}


                </Typography>

            </Box>

            <Box textAlign={"center"}
                 sx={{
                     backgroundColor: "#f6f6f6",
                     borderTop: "1px solid #6d6d6d"
                 }}>
                <Typography
                    color='textSecondary'
                    variant='caption'
                >
                    &copy; {new Date().getFullYear()} SeaCrush SASU, Reg #: IM078170016 {t("rights-reserved")}.
                </Typography>
            </Box>
        </>
    )
};
