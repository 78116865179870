import type {FC} from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import Image from "next/legacy/image";
import { useTranslation } from 'next-i18next'


interface Item {
    alt: string;
    file: string;
    text: string;
}

export const FooterPre: FC = (props) => {
    const { t } = useTranslation();
    const items: Item[] = [
        {
            alt: "badge",
            file: "footer-quality-badge.svg",
            text: t("footer_top-destinations")
        },
        {
            alt: "school of fish",
            file: "footer-fish-school.svg",
            text: t("footer_right-season")
        },
        {
            alt: "sea fan",
            file: "footer-conservation.svg",
            text: t("footer_with-responsible")
        },
        {
            alt: "wallet",
            file: "footer-wallet.svg",
            text: t("footer_no-fees")
        },
    ]


    return <Box
        sx={{
            backgroundColor: 'white',
            borderTopColor: 'divider',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            pb: 4,
            pt: {
                md: 3,
                xs: 3
            }
        }}
        {...props}
    >
        <Container maxWidth="lg">
            <Grid
                container
                justifyContent={"space-around"}
            >

                {items.map((val, idx) => {

                    return <Grid item key={idx} md={3} xs={6}>
                        <Box p={1} textAlign={"center"}>
                            <Box m={1} textAlign={"center"}>
                                <Image alt={val.alt} src={`/images/${val.file}`} width={48} height={48}/>
                            </Box>
                            <span dangerouslySetInnerHTML={{__html: val.text}}/>

                        </Box>
                    </Grid>

                })}

            </Grid>
        </Container>
    </Box>
}
