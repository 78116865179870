import type {FC} from "react";
import {useRouter} from "next-translate-routes/router";
import toast from "react-hot-toast";
import {
    Avatar,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography, ButtonBase, Badge
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import WarningIcon from "@mui/icons-material/Warning";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import AccountIcon from "@mui/icons-material/AccountCircle";

import {useAuth} from "../hooks/use-auth";
import {useTranslation} from "next-i18next";
import {useDispatch, useSelector} from "../store";
import {setShowOnboarding} from "../slices/onboarding-slice";
import UserIcon from "@mui/icons-material/AccountCircle";
import {useRef, useState} from "react";
import {setShowAuthWall} from "../slices/user-session-slice";
import axios from "axios";

import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccessibleIcon from "@mui/icons-material/Accessible";
import Link from "next-translate-routes/link";

function wait(timeout: number) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

const slowScroll = async () => {
    // Set the scroll step size
    const SCROLL_STEP = 2;
    const STEP_TIMEOUT = 20;

    let result = document.evaluate("//*[@id='__next']/div[2]/section/div/div/div[contains(@class,'MuiPaper-elevation')]",
        document,
        null,
        XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
        null
    )

    const stops: number[] = []
    for (let i = 0; i < result.snapshotLength; i++) {
        let node = result.snapshotItem(i) as HTMLDivElement;
        const top = node?.getBoundingClientRect().top;
        console.log(top)
        stops.push(top)
    }
    // const PAUSE_AMOUNT = 2000
    //
    let count = 0

    for (const stop of stops) {
        count += 1
        window.scrollTo({top: stop - 64 - 128, behavior: "smooth"})
        await wait(3900)
        // if (count > 5) return

    }

    // Scroll the page by the step size every 10 milliseconds
    // var scrollInterval = setInterval(function () {
    //     // Scroll down by the step size
    //     window.scrollBy(0, SCROLL_STEP);
    //     const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
    //
    //     console.log(scrollTop) // this number gets bigger as we scroll
    //     if (stops.length === 0) {
    //         clearInterval(scrollInterval);
    //     }
    //
    //     // If we have scrolled down by 1000 pixels, clear the interval
    //     if (currentPauseAmount <= 0 && scrollTop >= stops[0]) {
    //         console.log("should pause here at "+ stops[0])
    //         stops.shift()
    //         currentPauseAmount = PAUSE_AMOUNT
    //     } else {
    //         currentPauseAmount -= 1
    //     }
    // }, STEP_TIMEOUT);
}
export const AccountButton: FC = () => {

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState<boolean>(false);

    const handleOpenPopover = (): void => {
        setOpenPopover(true);
    };

    const handleClosePopover = (): void => {
        setOpenPopover(false);
    };

    const router = useRouter();

    const {logout, user, isAdmin, isAuthenticated, refreshToken} = useAuth();

    const {t} = useTranslation()

    const {showAuthWall} = useSelector((state) => state.userSession);

    const dispatch = useDispatch()

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            router.push("/").catch(console.error);
        } catch (err) {
            console.error(err);
            toast.error("Unable to logout.");
        }
    };


    const handleAdminToggle = async () => {


        try {


            const response = await axios.post("/api/admin", {
                targetUid: user?.uid,
                admin: !isAdmin
            }, {
                headers: {
                    "Context-Type": "application/json"
                }
            });

            toast.success(response.statusText)


            setTimeout(() => {
                refreshToken()
            }, 500)


        } catch (e) {
            toast.error(e.message)
        }
    }

    return (<>
            <Box
                aria-label='Toggle Personal Account Menu'
                component={ButtonBase}
                disableRipple={true}
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    ml: 2
                }}
            >
                <Badge color='secondary' variant='dot' invisible={openPopover || isAuthenticated || showAuthWall}
                       overlap='circular'
                >
                    <Avatar
                        sx={{
                            height: 40,
                            width: 40
                        }}
                        src={user?.photoUrl}
                    >
                        <UserIcon fontSize='large'/>
                    </Avatar>
                </Badge>
            </Box>
            <Popover
                disableScrollLock={true}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                // keepMounted
                onClose={handleClosePopover}
                open={openPopover}
                PaperProps={{sx: {width: 220, borderRadius: 1, mt: 1}}}
                transitionDuration={100}
            >
                {!isAuthenticated && <>
                    <Box sx={{my: 1}}>
                        <MenuItem onClick={(e) => {
                            dispatch(setShowAuthWall(true))
                            handleClosePopover()
                        }}>
                            <ListItemText
                                primary={(
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>
                                        {t("signup")}
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            dispatch(setShowAuthWall(true))
                            handleClosePopover()
                        }}>
                            <ListItemText
                                primary={(
                                    <Typography variant='body1'>
                                        {t("login")}
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                    </Box>
                </>
                }

                {isAuthenticated && <>
                    <Box
                        sx={{
                            alignItems: "center",
                            p: 2,
                            display: "flex"
                        }}
                    >
                        <Avatar
                            src={user?.photoUrl}
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        >

                            <WarningIcon fontSize='small'/>
                        </Avatar>
                        <Box
                            sx={{
                                ml: 1
                            }}
                        >
                            <Typography variant='body1'>
                                {user?.firstName} {user?.lastName}
                            </Typography>
                            <Typography
                                color='textSecondary'
                                variant='body2'
                            >
                                {user?.certBody} {user?.certLevel}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box sx={{my: 1}}>
                        <Link
                            href={{
                                pathname: "/u/[username]",
                                query: {
                                    username: user?.username
                                }
                            }}
                            passHref
                            legacyBehavior
                        >
                            <MenuItem
                                component={"a"}
                                onClick={handleClosePopover}
                            >
                                <ListItemIcon>
                                    <AccountIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant='body1'>
                                            {t("profile")}
                                        </Typography>
                                    )}
                                />
                            </MenuItem>
                        </Link>
                        <Link
                            href='/settings'
                            passHref
                            legacyBehavior
                        >
                            <MenuItem
                                component={"a"}
                                onClick={handleClosePopover}
                            >
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant='body1'>
                                            {t("settings")}
                                        </Typography>
                                    )}
                                />
                            </MenuItem>
                        </Link>

                        {process.env.NEXT_PUBLIC_ENV !== "production" && <>

                            <Divider/>

                            <MenuItem onClick={(e) => {
                                dispatch(setShowOnboarding(true))
                                handleClosePopover()
                            }}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant='body1'>
                                            Show Onboarding
                                        </Typography>
                                    )}
                                />
                            </MenuItem>
                        </>
                        }

                        {isAdmin && <>
                            <MenuItem onClick={(e) => {
                                handleClosePopover()
                                setTimeout(slowScroll, 3000)
                            }}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant='body1'>
                                            Slow Scroll
                                        </Typography>
                                    )}
                                />
                            </MenuItem>
                        </>
                        }

                        {(user?.uid && [
                                "RbgXb3JkycOH4lybNmKYQfTgDtS2",
                                "kOF5YAeoUGYvEkbw3kk2LH5Ds8i2",
                                "6ev7jnQ5qVYhpv2ccmOdVeLIg0b2",
                                "IlNFeySTgMXKKndDDyctYZesXKQ2"
                            ].includes(user?.uid))
                            && <>
                                <MenuItem onClick={(e) => {
                                    handleAdminToggle()
                                }}>
                                    <ListItemIcon>
                                        {isAdmin ?
                                            <AccessibleIcon/>
                                            :
                                            <AccessibilityNewIcon/>
                                        }

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Typography
                                                color='textPrimary'
                                                variant='body1'
                                            >
                                                {isAdmin ?
                                                    "Drop Admin" :
                                                    "Make Me A King"
                                                }
                                            </Typography>
                                        )}
                                    />
                                </MenuItem>
                            </>
                        }
                        <Divider/>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon fontSize='small'/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant='body1'>
                                        Logout
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                    </Box>
                </>}

            </Popover>
        </>
    );
};
