import React, {forwardRef, ForwardRefRenderFunction} from "react";
import {Link as LinkMUI, LinkProps as LinkMUIProps} from "@mui/material";
import NextLink from "next-translate-routes/link";
import {LinkProps as NextLinkProps} from "next/link";

export type LinkProps = Omit<LinkMUIProps, "href" | "classes">
    & Pick<NextLinkProps, "as" | "prefetch" | "href" | "locale">


export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({href, as, prefetch, locale, ...props}, ref) => {
    return <NextLink href={href} as={as} locale={locale} prefetch={prefetch} passHref legacyBehavior>
        <LinkMUI ref={ref} {...props} />
    </NextLink>
})
