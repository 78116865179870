import {createSvgIcon} from "@mui/material/utils";

export const CanHelpYou = createSvgIcon(
    <svg
         viewBox="0 0 20 20" version="1.1"
         xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, 1.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M7.75,10.925 C8.1625,10.925 8.4875,10.6125 8.4875,10.225 C8.4875,9.8375 8.15,9.525 7.75,9.525 C7.3375,9.525 7.0125,9.8375 7.0125,10.225 C7.0125,10.6125 7.3375,10.925 7.75,10.925 Z" id="Path"></path>
                <path d="M13.2125,10.225 C13.2125,9.8375 12.875,9.525 12.475,9.525 C12.0625,9.525 11.7375,9.8375 11.7375,10.225 C11.7375,10.6125 12.075,10.925 12.475,10.925 C12.875,10.925 13.2125,10.6125 13.2125,10.225 Z" id="Path"></path>
                <path d="M19.775,14.8875 C18.6125,13.875 18.5875,13.275 18.5625,12.4625 C18.5625,12.3625 18.5625,12.25 18.55,12.15 C18.5125,11.3375 18.5625,11.0875 18.7625,10.15 C18.8,9.9625 18.85,9.75 18.9,9.5125 C18.9,9.5 18.9,9.4875 18.9125,9.475 C19.0625,8.325 18.9875,7.1625 18.7875,6.2 C18.775,6.1625 18.7625,6.125 18.75,6.0875 C17.3,0.0125 10.8125,0.1375 10.75,0.1375 C10.7375,0.1375 10.725,0.1375 10.7,0.1375 C10.6625,0.1375 10.625,0.124903125 10.5875,0.124903125 C10.3875,0.124903125 5.4875,0.0625 2.75,3.1125 C1.3125,4.7125 0.75,6.85 1.075,9.475 C1.075,9.4875 1.075,9.5 1.0875,9.5125 C1.1375,9.7625 1.1875,9.9625 1.225,10.15 C1.425,11.0875 1.475,11.3375 1.4375,12.15 C1.4375,12.2625 1.425,12.3625 1.425,12.4625 C1.4,13.275 1.3875,13.8625 0.2125,14.8875 C0.0125,15.0625 -0.025,15.3625 0.1125,15.5875 C0.4,16.025 1.0875,16.6 2.1125,16.6 C2.6375,16.6 3.2375,16.45 3.925,16.075 C4.1875,15.925 4.275,15.6 4.1375,15.3375 C3.9875,15.075 3.6625,14.9875 3.4,15.125 C2.3625,15.7125 1.7,15.525 1.35,15.3 C2.4625,14.175 2.475,13.325 2.5,12.4875 C2.5,12.3875 2.5,12.2875 2.5125,12.1875 C2.5625,11.2375 2.475,10.875 2.275,9.9125 C2.2375,9.7375 2.2,9.5375 2.15,9.3 C1.8625,7.0125 2.3375,5.175 3.55,3.825 C5.95,1.15 10.525,1.2 10.575,1.2 L10.65,1.2 C10.6875,1.2125 10.7375,1.2125 10.7875,1.2125 C11.025,1.2125 16.625,1.15 17.775,6.55 C17.9375,7.375 17.9875,8.325 17.8625,9.3125 C17.8125,9.55 17.775,9.75 17.7375,9.925 C17.5375,10.8875 17.45,11.25 17.5,12.2 C17.5,12.3 17.5125,12.4 17.5125,12.5 C17.5375,13.325 17.5625,14.1875 18.6625,15.3125 C18.3125,15.5375 17.65,15.725 16.6125,15.1375 C16.35,14.9875 16.025,15.0875 15.875,15.35 C15.725,15.6125 15.825,15.9375 16.0875,16.0875 C16.775,16.475 17.375,16.6125 17.9,16.6125 C18.925,16.6125 19.6125,16.0375 19.9,15.6 C20.025,15.3625 19.9875,15.0625 19.775,14.8875 L19.775,14.8875 Z" id="Path"></path>
                <path d="M16.912527,10.8 C16.912527,10.6875 16.925,9.725 16.4125,9.1625 C16.4125,9.1625 16.4125,9.1625 16.4,9.15 C16.3375,7.9125 16.1,7 16.0875,6.9375 C16.0375,6.7375 15.8625,6.5875 15.675,6.55 C15.6375,6.5375 15.6,6.5125 15.5625,6.5 C15.475,6.475 13.3,5.8125 11.9875,4.4625 C11.6375,4.1 11.325,3.7875 10.9,3.875 C10.5,3.95 10.375,4.3 10.2125,4.7 C8.7125,8.575 4.9375,7.825 4.7875,7.8 C4.5,7.7375 4.2125,7.925 4.15,8.2125 C4.0875,8.5 4.275,8.7875 4.5625,8.85 C4.575,8.85 5,8.9375 5.625,8.9375 C7.1375,8.9375 9.8625,8.45 11.1875,5.1875 C11.2,5.2 11.2125,5.2125 11.2125,5.2125 C12.5375,6.575 14.525,7.3 15.1,7.4875 C15.225,8.075 15.4625,9.5375 15.25,11.0125 C15.25,11.0375 15.1,11.925 14.6875,13.0125 C14.0125,13.6625 12.9375,14.1875 11.225,14.1875 C10.9875,13.8875 10.55,13.675 10.0375,13.675 C9.275,13.675 8.65,14.1375 8.65,14.7125 C8.65,15.2875 9.275,15.75 10.0375,15.75 C10.525,15.75 10.9625,15.55 11.2,15.2625 L11.225,15.2625 C12.275,15.2625 13.1375,15.0875 13.8375,14.8 C13.0375,16.125 11.8125,17.2875 10.025,17.2875 C10,17.2875 9.9875,17.2875 9.9625,17.2875 C9.9375,17.2875 9.925,17.2875 9.9,17.2875 C5.775,17.2875 4.95,11.0875 4.9375,11.025 C4.9,10.725 4.6375,10.525 4.3375,10.5625 C4.0375,10.6 3.8375,10.8625 3.875,11.1625 C3.9125,11.4625 4.825,18.375 9.9125,18.375 L9.975,18.375 L10.0375,18.375 C13.225,18.375 14.875,15.6375 15.6625,13.5375 C16.7625,12.3 16.912527,10.8375 16.912527,10.8 L16.912527,10.8 Z" id="Path"></path>
            </g>
        </g>
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 20 20"
    //   fill="currentColor"
    // >
    //   <path
    //     fillRule="evenodd"
    //     d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
    //     clipRule="evenodd"
    //   />
    // </svg>
    ,
    "CanHelpYou"
);
