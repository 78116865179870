import {FC} from "react";
import {Typography, Box, Select, MenuItem} from "@mui/material";
import {useTranslation} from "next-i18next";
import {SelectChangeEvent} from "@mui/material/Select";
import {useRouter} from "next-translate-routes/router";
import tripDatabase from "../generated/trip-database.json";
import {useSelector} from "../store";
import countryDataJson from "../generated/country-data.json";

export const SiteLanguagePicker = () => {

    const {t, i18n} = useTranslation()

    const {locale, locales, pathname, query, route, push} = useRouter()

    const {selectedTripId} = useSelector(state => state.userSession)


    const handleChange = (e: SelectChangeEvent) => {

        const targetLocale = e.target.value;

        if (pathname.startsWith("/diving/[countrySlug]/[tripSlug]")) {
            // @ts-ignore
            const {
                countrySlug,
                tripSlug
                // @ts-ignore
            } = tripDatabase[selectedTripId as keyof typeof tripDatabase].slugs[targetLocale]

            // @ts-ignore
            push({
                pathname: "/diving/[countrySlug]/[tripSlug]",
                query: {countrySlug, tripSlug}
            }, undefined, {locale: targetLocale})
        } else if (pathname === "/diving/[countrySlug]") {

            const countrySlug = (countryDataJson as any).filter((c: any) => c.slug[i18n.language].current === query["countrySlug"])[0].slug[targetLocale].current

            push({
                pathname: "/diving/[countrySlug]",
                query: {countrySlug}
            }, undefined, {locale: targetLocale})

        } else {
            push({pathname, query}, undefined, {locale: targetLocale})
        }


    }

    return <Select
        sx={{
            boxShadow: "none", ".MuiOutlinedInput-notchedOutline": {border: 0},
            "& .MuiSvgIcon-root": {
                color: "white",
            },

        }}
        onChange={handleChange}
        value={i18n.language}
        renderValue={(val: string) => {
            return <Box>
                <img src={`/images/flags/lang/${val}.png`}
                     srcSet={`/images/flags/lang/${val}.png, /images/flags/lang/${val}@2x.png 2x`}
                     style={{
                         height: 28,
                         width: 28,
                         marginRight: 6,
                         verticalAlign: "middle"
                     }}
                    // @ts-ignore
                     alt={
                         // @ts-ignore
                         t(val)
                }/><Typography display={"inline"} color={"white"}> {t(val as "en" | "fr")}</Typography>
            </Box>
        }}
    >
        {locales?.filter((val) => val !== "default").map((newLocale, idx) => {
            return <MenuItem key={idx} value={newLocale}>{t(newLocale as "en" | "fr")}</MenuItem>
        })}


    </Select>

}
